<template>
    <div class="p-grid panel-demo">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <h5>ArenaCX Marketplace Portal</h5>

                <div class="para">Review the ArenaCX services you have active today, and learn about new ones.</div>
            </div>
        </div>

        <div class="p-col-12 p-md-4" v-for="category in featuresByCategory" :key="category.categoryId">
            <div class="card">
                <div class="p-d-flex p-jc-between">
                    <div>
                        <h5>{{ category.categoryName }}</h5>
                    </div>
                    <div>
                        <img
                            :src="'./assets/layout/images/' + category.categoryName.toLowerCase() + '_icon.png'"
                            style="height: 24px"
                        />
                    </div>
                </div>
                <Accordion :activeIndex="category.categoryId == 0 ? 0 : -1">
                    <AccordionTab v-for="feature in category.features" :key="feature.featureId">
                        <template v-slot:header>
                            <div class="p-d-flex p-ai-center">
                                <i
                                    class="pi pi-circle-on fs-med p-mr-3"
                                    :class="feature.accountFeatures.length > 0 ? 'serviceOn' : 'serviceOff'"
                                ></i>
                                <div class="p-d-flex p-flex-column">
                                    <h6 class="p-mb-0">{{ feature.name }}</h6>
                                </div>
                            </div>
                        </template>
                        <div class="featureDescription">
                            {{ feature.description }}
                        </div>
                        <div class="p-grid p-justify-end" v-if="false">
                            <div class="box"><Button label="Info" class="p-button-text p-mr-2 p-mb-2">Learn more</Button></div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import MemberHomeService from '../service/MemberHomeService';
import { getAccountId } from '../service/common';
//import { reactive } from 'vue';

export default {
    setup() {
        const featuresByCategory = ref({});
        return {
            featuresByCategory,
        };
    },
    data() {
        return {
            featureData: null,
            //featuresByCategory: {},
            memberId: null,
            oddity: 0,
        };
    },

    memberHomeService: null,
    tempFeaturesByCategory: null,

    async updated() {
        this.memberId = getAccountId();
    },

    async mounted() {
        this.memberId = getAccountId();
    },

    watch: {
        memberId: async function (newValue) {
            if (this.memberId) {
                this.featuresByCategory = await this.loadFeatures(newValue);
                //this.featuresByCategory = this.tempFeaturesByCategory;
                //this.oddity = this.featuresByCategory[2].features[0].accountFeatures.length;
            }
        },
        featuresByCategory: function () {
            console.log('watcher for featuresByCategory fired');
        },
    },

    methods: {
        loadFeatures: async function (newMemberId) {
            this.memberHomeService = new MemberHomeService();
            await this.memberHomeService.getMemberHomeFeatureData(newMemberId).then((data) => (this.featureData = data));
            return this.getByCategory();
        },

        toggle(event) {
            this.$refs.menu.toggle(event);
        },

        toggleMsg(event) {
            this.$refs.op.toggle(event);
        },

        // Need to group them by category so that UI can group them
        getByCategory() {
            var dataByCategory = [];

            var objFind = {};
            objFind.categoryId = 0;
            objFind.categoryName = 'Find';
            objFind.features = this.featureData.filter(function (item) {
                return item.category == 0;
            });
            dataByCategory.push(objFind);

            var objManage = {};
            objManage.categoryId = 1;
            objManage.categoryName = 'Manage';
            objManage.features = this.featureData.filter(function (item) {
                return item.category == 1;
            });
            dataByCategory.push(objManage);

            var objOptimize = {};
            objOptimize.categoryId = 2;
            objOptimize.categoryName = 'Optimize';
            objOptimize.features = this.featureData.filter(function (item) {
                return item.category == 2;
            });
            dataByCategory.push(objOptimize);

            return dataByCategory;
        },
    },
};
</script>

<style scoped lang="scss">
.para {
    line-height: 1.5;
    margin: 0;
}

.serviceOn {
    color: rgb(96, 182, 149);
}

.serviceOff {
    color: lightgrey;
}

.definition {
    font-size: 90%;
    color: #555555;
}

.nounVerb {
    font-weight: bold;
    font-style: italic;
}

.featureDescription {
    margin-right: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 3em;
}
</style>
